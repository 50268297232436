// Note - general styling for graph/infographic elements. Chartist-related styles are served separately as large and not always required, see modules/chartist
.graph {
  &:not(:last-child) {
    margin-bottom: $uks-component-margin;
  }

  &__title {
    margin-bottom: rem(20);
  }

  &__large-text {
    margin-bottom: rem(12);
    font-size: rem(40);
    font-weight: 700;
    line-height: (47 / 40);

    @include respond-up-to($uks-screen-small-2) {
      @include hyphens;
    }
  }

  &__largest-text {
    margin-top: rem(-6);
    margin-bottom: rem(5);
    font-size: rem(50);
    font-weight: 700;
    line-height: (59 / 50);
  }

  &__people {
    display: flex;
    flex-wrap: wrap;
    margin: #{0} rem(-10);
  }

  &__person {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: flex-end;
    width: (100% / 3);
    padding: #{0} rem(10);
    margin-bottom: rem(20);
  }

  &__person-icon {
    max-width: 50%;
    height: auto;
    margin: 0 auto;
  }

  &__comparison {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 5px solid $black;

    @include media-breakpoint-up(md) {
      padding-bottom: 36px;
      border-width: 10px;

      main .row & {
        padding-bottom: 16px;
        border-width: 5px;
      }
    }

  }

  &__comparison-item {
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    width: calc(50% - 2px);
    overflow: hidden;
    // Leave here
    font-size: 81px;

    @include media-breakpoint-up(md) {
      font-size: 162px;

      main .row & {
        font-size: 81px;
      }
    }
  }

  &__comparison-text {
    display: inline-block;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
  }

  &__column,
  &__100 {
    @include media-breakpoint-up(md) {
      display: flex;
      gap: 0 30px;

      main .row & {
        display: block;
      }
    }
  }

  &__column-column,
  &__100-dots {
    display: flex;
    flex-wrap: wrap;
    max-width: 446px;
    margin: 0 -6px -12px;

    @include media-breakpoint-up(md) {
      flex: 0 0 auto;
      order: 1;
    }
  }

  &__100-item {
    flex: 0 0 auto;
    width: 10%;
    padding: 0 6px;
    margin-bottom: 12px;
  }

  &__100-icon {
    display: block;
    width: 100%;
    height: auto;
    color: $uks-color-tones-14;
    border-radius: 100%;

    &--1 {
      color: $uks-color-tones-19-alt;
      border-radius: 0;
    }

    &--2 {
      color: $uks-color-tones-13;
      border-radius: 0;
    }
  }

  &__column-column {
    width: 100%;
    height: 348px;
    margin: 0;
  }

  &__column-item {
    position: relative;
    width: 100%;
    background-color: $uks-color-tones-14;

    &--color-1 {
      background-color: $uks-color-tones-19-alt;
    }

    &--color-2 {
      background-color: $uks-color-tones-13;
    }
  }

  &__column-item-label {
    position: absolute;
    bottom: 6px;
    left: 6px;
    padding: 0 2px;
    font-size: rem(12);
    font-weight: 500;
    background: $white;
  }

  &--progress-people {
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: flex-start;
      gap: 0 30px;

      main .row & {
        display: block;
      }
    }
  }

  &__progress--people {
    @include media-breakpoint-up(md) {
      flex: 0 0 auto;
      width: 100%;
      max-width: 350px;

      main .row & {
        display: block;
        max-width: none;
      }
    }
  }

  &__progress-bar {
    width: 100%;
    /* stylelint-disable property-no-unknown */
    aspect-ratio: 728 / 58;
    /* stylelint-enable property-no-unknown */
    mask: url("/eecs/fileadmin/sys/resources/images/graph/progress-mask.svg");
    mask-size: 100% auto;
    background: $uks-color-tones-20;

    &--people {
      /* stylelint-disable property-no-unknown */
      aspect-ratio: 137 / 20;
      /* stylelint-enable property-no-unknown */
      mask: url("/eecs/fileadmin/sys/resources/images/graph/progress-people-mask.svg");
      mask-size: 100% auto;
    }
  }

  &__progress-progress {
    height: 100%;
    background: $uks-color-tones-14;
  }

  &__legend-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: rem(20) 0 0;
    font-size: rem(14);
    line-height: (20 / 14);
    list-style: none;

    &--compact {
      gap: rem(6) rem(20);
    }

    &--full {
      display: block;
      margin: 0 0 rem(36);

      @include media-breakpoint-up(md) {
        flex: 1 1 auto;
        order: 2;
        margin: 0;

        main .row & {
          margin-bottom: rem(36);
        }
      }
    }

    &--donut {
      position: relative;
    }
  }

  &__legend-item {
    &--full {
      position: relative;
      padding-left: 30px;
      margin: 0 0 rem(12);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__legend-item-key {
    @include graph-legend-keys;

    &--full {
      position: absolute;
      top: 1px;
      left: 0;
      margin: 0;
    }

    &--plain {
      width: 20px;
      height: 20px;
      background-color: $uks-color-tones-14;
    }

    &--plain-1 {
      background-color: $uks-color-tones-19-alt;
    }

    &--plain-2 {
      background-color: $uks-color-tones-13;
    }

    &--plain-neutral {
      background-color: $uks-color-tones-20;
    }
  }

  &__description {
    position: relative;
    margin: rem(16) 0 0;
    font-size: rem(18);
    line-height: (28 / 18);

    &--comparison,
    &--donut {
      text-align: center;
    }

    &--progress {
      margin-top: rem(28);
    }

    &--progress-people {
      margin-top: rem(10);

      @include media-breakpoint-up(md) {
        flex: 1 1 auto;
        margin: 0;

        main .row & {
          margin-top: rem(10);
        }
      }
    }
  }

  &__donut-wrapper {
    width: 232px;
    max-width: 100%;
    margin: 0 auto;
    /* stylelint-disable property-no-unknown */
    aspect-ratio: 2 / 1;
    /* stylelint-enable property-no-unknown */
    overflow: hidden;
  }

  &__donut-circle {
    position: relative;
    /* stylelint-disable property-no-unknown */
    aspect-ratio: 1 / 1;
    /* stylelint-enable property-no-unknown */
    border-radius: 100%;
    transform: rotate(-90deg);

    &::after {
      position: absolute;
      top: 0;
      right: calc(50% - 1px);
      bottom: 0;
      left: -1px;
      content: "";
      background: #fff;
    }
  }

  &__donut-hole {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: (154 / 232) * 100%;
    height: (154 / 232) * 100%;
    background: #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
}
